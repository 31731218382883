import React, { useState, useContext, useEffect } from "react";
import { NavLink as Linkto, useHistory, useLocation } from "react-router-dom";
import Draggable from "react-draggable";
import styled from "styled-components/macro";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import Moment from "moment";
import "moment/locale/es";
Moment.locale("es");

import {
    Divider as MuiDivider,
    Grid,
    Typography,
    FormControl,
    Button,
    IconButton,
    TextField,
    InputLabel,
    MenuItem,
    Select,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Paper as MuiPaper,
    InputAdornment,
    DialogContentText,
    Checkbox,
    FormControlLabel,
    Label,
    FormGroup,
    FormHelperText,
    List,
    ListItem,
    ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { actions } from "../../redux/actions/customerBusinessActions";
import { AuthContext } from "../../redux/store/auth-context";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import { spacing } from "@material-ui/system";
import NumberFormat from "react-number-format";

const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    title: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        padding: "0.5rem 1rem",
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        width: "100%",
    },
    odd: {
        backgroundColor: "#c6c6c6",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    attr: {
        display: "inline-block",
    },
    attrContainer: {
        position: "relative",
        maxWidth: "400px",
        display: "inline-block",
        padding: 15,
    },
    attrImage: {
        width: 150,
    },
    attrOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: "100%",
        width: "100%",
        opacity: 0,
        transition: ".3s ease",
        backgroundColor: "black",
        "&:hover": {
            opacity: 0.7,
        },
    },
    attrIcon: {
        color: "white",
        fontSize: 12,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -68%)",
        textAlign: "center",
        textDecoration: "none",
        "&:hover": {
            color: "#eee",
        },
    },
    formControl: {
        margin: theme.spacing(1),
        width: "95%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    active: {
        display: "block",
    },
    inactive: {
        display: "none",
    },
    label: {
        fontSize: "10px !important",
        color: "rgba(0, 0, 0, 0.38)",
    },
}));


function CustomerBusinessEdit(props) {
    const auth = useContext(AuthContext);
    const [userId, setUserId] = useState(
        props.match.params.userId ? props.match.params.userId : false
    );
    const businessId = props.match.params.id ? props.match.params.id : false;
    const dispatch = useDispatch();
    const redData = useSelector((state) => state.customerBusinessReducer);
    const [rows, setRows] = useState("");
    const [plans, setPlans] = useState("");
    const [statuses, setStatuses] = useState("");
    const location = useLocation();
    const filterApplieds = location.state?.datas;
    const backUrl = location.state?.backUrl;
    const userData = location.state?.userData;
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [configs, setConfigs] = useState("");
    const [editable, setEditable] = useState(false);
    const [isFeeError, setIsFeeError] = useState(false);
    const [businessFantasy, setBusinessFantasy] = useState(false);
    const [planPrice, setPlanPrice] = useState("");

    const create = ["create_customer_business"].includes(
        location.pathname.split("/")[1]
    );
    const [productsPlan, setProductsPlan] = useState([]);
    const [productsPlanInitial, setProductsPlanInitial] = useState("");

    const [loading, setLoading] = useState(false);
    const [productsPlanValue, setProductsPlanValue] = useState(false);
    const [business, setBusiness] = useState(false);


    useEffect(() => {
        dispatch(
            actions({
                url: businessId
                    ? `customer_business/?id=${businessId}`
                    : "customer_business/",
                token: auth.token,
                action: "list",
            })
        );
    }, [auth.token]);


    useEffect(() => {
        console.log("redData:");
        console.log(redData);
        if (redData?.rows?.constructor == Object) {
            console.log(redData.rows?.rows[0], "rows reddata");
            setRows(redData.rows?.rows[0]);
            setBusiness(redData.rows?.rows[0]?.business);
            let products_plan_list = []
            redData.products_plan.map(product => {
                products_plan_list.push(product.method.id)
            })
            setProductsPlan(products_plan_list);
            setProductsPlanInitial(redData.products_plan_initial);
            setPlanPrice(redData.rows?.rows[0].plan?.price);
        } else if (redData?.rows !== undefined) {
            setRows(redData?.rows[0]);
            setBusiness(redData?.rows[0]?.business);
            setProductsPlan(redData?.products_plan);
            setProductsPlanInitial(redData?.products_plan_initial);
            setPlanPrice(redData?.rows[0]?.plan?.price)
        }
        if (redData?.products_plan_initial) {
            let value = Object.assign(
                {},
                ...redData.products_plan_initial.map((product) => ({
                    [product?.name.toLowerCase().replaceAll(" ", "_").concat("_price")]: redData.products_plan?.find(p => p.method?.id == product?.id)?.price || product?.price_plan, [product?.name.toLowerCase().replaceAll(" ", "_").concat("_fee")]: redData.products_plan?.find(p => p.method?.id == product?.id)?.fee || product?.fee_plan
                }))
            );
            setProductsPlanValue(value);
        }

    }, [redData]);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setAnchorEl(null);
        setOpen(false);
    };

    const calculatePrice = (products) => {
        let priceTotal = 0
        let new_products = [...new Set(products)];
        console.log(new_products, "new_products")
        new_products.map(product_id => {
            let product = productsPlanInitial.find(product => product.id == product_id);
            console.log(product_id, "product_id calculate price")
            console.log(product, "product")
            console.log(`${product?.name.toLowerCase().replaceAll(" ", "_")}_price`, "product_id")
            let price = +document.getElementById(`${product?.name.toLowerCase().replaceAll(" ", "_")}_price`)?.value.replaceAll('.', '');
            priceTotal += price
        })
        setPlanPrice(priceTotal)
    }

    return (
        redData && (
            <React.Fragment>
                <Helmet title="Editar Productos" />
                <Grid justify="space-between" container spacing={24}>
                    <Grid item className={classes.title}>
                        <Typography variant="h3" gutterBottom display="inline">
                            <Tooltip title="Atras">
                                <IconButton
                                    aria-label="Atras"
                                    component={Linkto}
                                    to={backUrl}
                                >
                                    <KeyboardArrowLeftIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                            {rows && rows?.business_name}
                            {/*  <Chip
                label={rows?.profile}
                size="small"
                style={{ marginLeft: "1rem" }}
              /> */}
                        </Typography>
                    </Grid>
                </Grid>
                <>
                    <Divider my={6} />
                    <Grid container spacing={6} key="gc-2">
                        <Grid item xs={12} key="g-2">
                            <Paper p={10}>
                                {productsPlanValue && productsPlanInitial ? (
                                    <Formik
                                        /*  enableReinitialize={true} */
                                        initialValues={{
                                            products_plan: productsPlan,
                                            ...productsPlanValue
                                        }}
                                        validationSchema={Yup.object().shape({
                                            products_plan: Yup.array().
                                                min(
                                                    1,
                                                    "Debe seleccionar por lo menos un producto"
                                                ),
                                        })}
                                        onSubmit={async (
                                            values,
                                            { setErrors, setStatus, setSubmitting }
                                        ) => {
                                            console.log("entra en submit");
                                            console.log(values);
                                            let data = values;
                                            data["plan_price"] = planPrice;
                                            let products_plan_edit_detail = [];
                                            let products_plan_edit = [...new Set(values.products_plan)];
                                            products_plan_edit.map((product) => {
                                                let productInitial = productsPlanInitial.find(pro => pro.id == product)
                                                let price = +values[`${productInitial.name.toLowerCase().replaceAll(" ", "_")}_price`]
                                                let fee = values[`${productInitial.name.toLowerCase().replaceAll(" ", "_")}_fee`]
                                                products_plan_edit_detail.push({ method_id: product, price: price, fee: typeof fee == "string" ? fee.replaceAll(",", ".") : fee })
                                            })
                                            console.log(products_plan_edit_detail, "products_plan_edit_detail");
                                            /*  data["products_plan_create"] = products_plan_create; */
                                            data["products_plan_edit_detail"] = products_plan_edit_detail;

                                            try {
                                                console.log(data, "data submit")
                                                delete data.user;
                                                dispatch(
                                                    actions({
                                                        url: `customer_business/edit_products/${businessId}/`,
                                                        token: auth.token,
                                                        action: "update",
                                                        data: data,
                                                    })
                                                );
                                                setSubmitting(true);
                                                history.push(backUrl);
                                            } catch (error) {
                                                console.log(error);
                                                const message =
                                                    "Algo salió mal en el servidor, verifique los campos y vuelva a intentarlo";
                                                setStatus({ success: false });
                                                setErrors({ submit: message });
                                                setSubmitting(false);
                                                alert(message);
                                            }
                                        }}
                                    >
                                        {({
                                            errors,
                                            handleBlur,
                                            handleChange,
                                            handleSubmit,
                                            isSubmitting,
                                            touched,
                                            setFieldValue,
                                            values,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Typography>
                                                    Productos incluídos:
                                                </Typography>
                                                <Grid
                                                    item
                                                    md={12}
                                                >
                                                    <FormControl
                                                        component="fieldset"
                                                        error={
                                                            errors.products_plan &&
                                                            touched.products_plan
                                                        }
                                                    >
                                                        <Typography
                                                            style={{
                                                                fontSize: 10,
                                                                color: "rgba(0, 0, 0, 0.38)",
                                                            }}
                                                        >
                                                            Seleccione el/los productos a incluir en el plan
                                                        </Typography>
                                                        <FormGroup
                                                            aria-label="position"
                                                            row
                                                            id="checkbox-group"
                                                        >
                                                            {console.log(productsPlan, "productsPlan")}
                                                            {console.log(productsPlanInitial, "productsPlanInitial")}
                                                            {productsPlanInitial.map((product, i) => (
                                                                <FormControlLabel
                                                                    key={i}
                                                                    role="group"
                                                                    aria-labelledby="checkbox-group"
                                                                    control={
                                                                        <Checkbox
                                                                            name="products_plan"
                                                                            type="checkbox"
                                                                            /*    {...(product.name == 'Cobro Rápido' && {
                                                                                   defaultChecked: true,
                                                                                   disabled: true
                                                                               })} */
                                                                            checked={productsPlan?.filter(p => p == product?.id).length > 0}
                                                                            style={{ paddingTop: 0 }}
                                                                            onChange={(e) => {
                                                                                const value = e.target.checked ? product.id : null
                                                                                let newProductsPlan = []
                                                                                if (value) {
                                                                                    if (values.products_plan.filter(pp => pp == product.id).length == 0) {
                                                                                        newProductsPlan = values.products_plan
                                                                                        newProductsPlan.push(value)
                                                                                        setFieldValue('products_plan', newProductsPlan)
                                                                                        setProductsPlan(newProductsPlan)
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    newProductsPlan = values.products_plan.filter(pp => pp != product.id)
                                                                                    setFieldValue('products_plan', newProductsPlan)
                                                                                    setProductsPlan(newProductsPlan)
                                                                                }
                                                                                calculatePrice(newProductsPlan);
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                    }
                                                                    label={
                                                                        <Grid container spacing={4} >
                                                                            <Grid item md={4}>
                                                                                <TextField
                                                                                    margin="dense"
                                                                                    label="Nombre"
                                                                                    type="text"
                                                                                    key="product"
                                                                                    fullWidth
                                                                                    value={product.description}
                                                                                    InputProps={{
                                                                                        readOnly: true,
                                                                                    }}
                                                                                    variant="standard"
                                                                                    disabled
                                                                                />
                                                                            </Grid>
                                                                            <Grid item md={4}>
                                                                                <NumberFormat
                                                                                    margin="dense"
                                                                                    label="Precio mensual"
                                                                                    id={`${product.name.toLowerCase().replaceAll(" ", "_")}_price`}
                                                                                    key={`${product.name.toLowerCase().replaceAll(" ", "_")}_price`}
                                                                                    name={`${product.name.toLowerCase().replaceAll(" ", "_")}_price`}
                                                                                    customInput={TextField}
                                                                                    prefix={""}
                                                                                    type="text"
                                                                                    thousandSeparator={"."}
                                                                                    decimalSeparator={","}
                                                                                    autoFocus={true}
                                                                                    autoComplete="off"
                                                                                    value={values[`${product.name.toLowerCase().replaceAll(" ", "_")}_price`]}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                Gs.
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    fullWidth
                                                                                    onChange={(e) => {
                                                                                        setFieldValue(
                                                                                            `${product.name.toLowerCase().replaceAll(" ", "_")}_price`,
                                                                                            e.target.value.replace(".", "")
                                                                                        );
                                                                                        calculatePrice(values.products_plan)
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item md={4}>
                                                                                <NumberFormat
                                                                                    margin="dense"
                                                                                    label="Comisión por transacción"
                                                                                    id={`${product.name.toLowerCase().replaceAll(" ", "_")}_fee`}
                                                                                    key={`${product.name.toLowerCase().replaceAll(" ", "_")}_fee`}
                                                                                    name={`${product.name.toLowerCase().replaceAll(" ", "_")}_fee`}
                                                                                    customInput={TextField}
                                                                                    prefix={""}
                                                                                    type="text"
                                                                                    decimalSeparator={","}
                                                                                    autoFocus={true}
                                                                                    autoComplete="off"
                                                                                    value={values[`${product.name.toLowerCase().replaceAll(" ", "_")}_fee`]}
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                %
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                    fullWidth
                                                                                    onChange={(e) => {
                                                                                        setFieldValue(
                                                                                            `${product.name.toLowerCase().replaceAll(" ", "_")}_fee`,
                                                                                            e.target.value.replace(".", "")
                                                                                        );
                                                                                    }}
                                                                                />

                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                /*  className={classes.label} */
                                                                />
                                                            ))}
                                                        </FormGroup>
                                                        {errors.products_plan &&
                                                            touched.products_plan && (
                                                                <FormHelperText>
                                                                    {errors.products_plan}
                                                                </FormHelperText>
                                                            )}
                                                    </FormControl>
                                                    <Grid item md={6}>
                                                        <NumberFormat
                                                            margin="dense"
                                                            id="plan_price"
                                                            name="plan_price"
                                                            label="Total mensual"
                                                            key="plan_price"
                                                            customInput={TextField}
                                                            prefix={""}
                                                            type="text"
                                                            thousandSeparator={"."}
                                                            decimalSeparator={false}
                                                            autoFocus={true}
                                                            autoComplete="off"
                                                            value={planPrice}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        Gs
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onChange={(e) => {
                                                                setPlanPrice(
                                                                    e.target.value.replace(".", "")
                                                                );
                                                            }}
                                                            disabled={editable}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="flex-end"
                                                    alignItems="center"
                                                    spacing={2}
                                                    style={{ paddingTop: "20px" }}
                                                >
                                                    <Grid item>
                                                        <Button
                                                            aria-controls="simple-menu"
                                                            variant="contained"
                                                            color="primary"
                                                            type="submit"
                                                            disabled={isSubmitting || isFeeError}
                                                        >
                                                            Confirmar
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            color="primary"
                                                            variant="outlined"
                                                            component={Linkto}
                                                            to={{
                                                                pathname: backUrl,
                                                                state: {
                                                                    datas: filterApplieds,
                                                                },
                                                            }}
                                                        >
                                                            Cancelar
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )}
                                    </Formik>
                                ) : (
                                    <p>Cargando datos...</p>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </>
                <Dialog
                    disableBackdropClick
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    PaperComponent={PaperComponent}
                    style={{ minWidth: 600 }}
                >
                    <DialogTitle id="form-dialog-title">Aviso</DialogTitle>
                    <Divider my={1} />
                    <DialogContent>
                        <Typography variant="h6" gutterBottom display="inline">
                            Datos actualizados exitosamente. El monto de suscripción entrará en vigencia a partir del periodo siguiente al cambio realizado.
                        </Typography>
                    </DialogContent>
                    <Divider my={3} />
                    <DialogActions>
                        <Button
                            color="primary"
                            component={Linkto}
                            to={{
                                pathname: backUrl,
                                state: {
                                    datas: filterApplieds,
                                },
                            }}
                        >
                            Aceptar y Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment >
        )
    )
}

export default CustomerBusinessEdit;
